//common
export const percent = "%";
export const notApplicable = "N/A";
export const chineseLang = "cn";
export const businessUnitNmbr = "1";
export const languageNumber = "1";
export const mainAddressTypeNo = 1;
export const mpfBillingAddressTypeNo = 104;
export const orsoBillingAddressTypeNo = 100;
export const aorsoBillingAddressTypeNo = 109;
export const officeAddressTypeNo = 102;
export const invTypeNumCash = 111;
export const processorId = "RSC_NEW_APP_USR";
export const s200 = "Principal MPF Scheme Series 200";
export const s600 = "Principal MPF Scheme Series 600";
export const s800 = "Principal MPF Scheme Series 800";
export const s200zh = "信安強積金計劃200系列";
export const s600zh = "信安強積金計劃600系列";
export const s800zh = "信安強積金計劃800系列";
export const transTyp = "AO2";
export const contributionRecord = "contributionRecord";
export const beforeMerger = " (Before Merger)";
export const beforeMergerChinese = " (合併前)";
export const invalidDate = "INVALID DATE";
export const DIS = "DIS";
export const DIS800 = "(DIS)-800";
export const DIS_SIMP = "(DIS)-SIMP";
export const DIS_SMAP = "(DIS)-SMAP";
export const transTypAI2 = "AI2";
export const transTypAO2 = "AO2";
export const transTypAI2Conversion = "AI2-CONVERSION";
export const transTypAO2Conversion = "AO2-CONVERSION";
export const transTypAI1RetainInSame = "AI1-RETAIN IN SAME SCHEME";
export const transTypAO1RetainInSame = "AO1-RETAIN IN SAME SCHEME";
export const transTypPA2Overpay = "PA2-OVERPAY";
export const transTypPD7EEChoice = "PD7-EE CHOICE";
export const productTypeMPF = "MPF";
export const productTypeORSO = "ORSO";
export const ltfgTransacrion = "principal long term guaranteed fund";
export const creditBlanceFund = "CREDIT BALANCE FUND";
export const DISfundName = "DEFAULT INVESTMENT STRATEGY (DIS)";
export const ChinesePM = " 下午 ";
export const ChineseAM = " 上午 ";
export const SequenceNumber = 'sequenceNumber';
export const BadAddress = "Bad Address";
export const BadAddressZh = "地址错误";
export const InactiveClient = "Inactive Client";
export const InactiveClientZh = "非活动客户端";
export const OthersAddr = "Others (Not in the above)";
export const ReturnedMail = "Returned Mail";
export const effectiveDate = "efctvDt";
export const descendingOrder = "desc";
export const goToPageSwitchOut = "switchOut";
export const goToPageFutCon = "futureContributionTypes";
export const goToPageCon = "contributionTypes";
export const schemeS800 = "S800";
export const schemeAIIB = "HKAIIB";
export const principalERName = "Principal Employer Name";
export const principalACName = "Principal Account Name";
export const policyNumber = "Policy Number";
export const disBrackets = "(DIS)";
export const hk = "HK";
export const us = "US";
export const USDots = "U.S.";
export const lssBrackets = "(LSS)";
export const aiibBrackets = "(AIIB)";
export const bank = "Bank";
export const employer = "Employer";
export const erEEInvTypeOrso = "Employer and  Member Contribution";
export const erEEInvType = "Bank and Member Contribution";
export const lssNoteFS = "LSS is not a fund, it is a strategy that uses two constituent funds, namely the Principal International Bond Fund (LSS) and Principal International Equity Fund (LSS) to automatically reduce the risk exposure as the member approaches retirement age. ";
export const futureBankContribution = "Bank Contribution on Changing Future Assets";
export const futureMemberContribution = "Member Contribution on Changing Future Assets";
export const futureBankAndMemberContribution = "Bank and Member Contribution on Changing Future Assets";
export const futureEmployerContribution = "Employer Contribution on Changing Future Assets";
export const futureEmployerAndMemberContribution = "Employer and Member Contribution on Changing Future Assets";
export const disA65F = "A65F-800-(DIS)";
export const caf800 = "CAF-800";
export const disCAF800 = "CAF-800-(DIS)";
export const a65f800 = "A65F-800";
export const STABLE = "STABLE";
export const administrationForms = "Administration Forms";
export const fatcaForm = "FATCA Certification Form";
export const selfCertificationForms = "Self-Certification Form - Individual";
export const personalInformationForms = "Personal Information Forms";
export const changePersonalInformation = "Change of Personal Information Form";
export const lssFundTitle = "Principal Lifestyle Strategy (LSS)";
export const transaction = "Transaction";
export const rebalancingHistory = "Rebalancing History";
export const equityHolding = "Equity Holding";
export const bondHolding = "Bond Holding";
export const total = "Total";
export const percentOf = "% of ";
export const age = "Age";
export const balance = "Balance";
export const date = "Date";
export const currency = "Currency";
export const units = "Units";
export const fundPrice = "Fund Price";
export const amount = "Amount";
export const equityVsBond = "Equity vs Bond";
export const beginning = "Beginning";
export const ending = "Ending";
export const note = "Note";
export const lssrhNote = "Value of constituent funds are rounded down to two decimal places, you may notice slight differences in your totals.";
export const lssRHNoRecords = "No Record Available for LSS Rebalancing History At A Momemt";
export const lssrher = "lssrher";
export const lssrhee = "lssrhee";
export const hash = "#";
export const colon = ":";
export const enUS = "en-US";
export const enGB = "en-GB";
export const zhHK = "zh-HK";
export const asiaHongKong = "Asia/Hong_Kong";
export const numeric = "numeric";
export const twoDigit = "2-digit";
export const BadAddressText = "BAD ADDRESS,BAD ADDRESS";
export const ascendingOrder = "asc";
export const PRODUCT_TYPE_NMBR_ORSO_CHUBB = 142;
export const PRODUCT_TYPE_NMBR_ORSO_FTLIFE = 143;
export const PRODUCT_TYPE_NMBR_ORSO_PTC = 105;
export const PRODUCT_TYPE_NMBR_ORSO_MAERSK = 122;
export const PRODUCT_TYPE_NMBR_ORSO_CRC = 124;
export const PRODUCT_TYPE_NMBR_ORSO_HKSI = 120;
export const PRODUCT_TYPE_NMBR_ORSO_PD = 125;
export const productTypeNumberOrsoArr = [142, 105, 122, 124, 120, 125];
export const productTypeNumbersMPF = [110, 111, 112, 113, 114, 115, 116, 117, 123, 129, 130];

//Link Constants
export const PIHK_WEBSITE_URL = "https://www.principal.com.hk";
export const PIHK_WEBSITE_URL_zh = "https://www.principal.com.hk/zh";
export const verificationNeededFormLink = "https://www.principal.com.hk/resources/downloads";
export const verificationNeededFormLinkZh = "https://www.principal.com.hk/zh/resources/downloads";
export const disLinkConst = "https://www.principal.com.hk/rsc-dis-disclosure-business-rules";
export const disLinkZhConst = "https://www.principal.com.hk/zh/rsc-dis-disclosure-business-rules";
export const glossaryLinkConst = "https://www.principal.com.hk/rsc-glossary";
export const glossaryLinkZhConst = "https://www.principal.com.hk/zh/rsc-glossary";
export const tocConst = "https://www.principal.com.hk/rsc-terms-e-communicationservice";
export const tocZhConst = "https://www.principal.com.hk/zh/rsc-terms-e-communicationservice";
export const schemeBrochureLinkConst = "https://www.principal.com.hk/sites/default/files/general-files/S800_E.pdf";
export const schemeBrochureLinkZhConst = "https://www.principal.com.hk/sites/default/files/general-files/S800_C.pdf";
export const brochureLinkOrso = "https://www.principal.com.hk/sites/default/files/general-files/ORSO_E.pdf";
export const brochureLinkOrsoPD_EN = "https://iframe.principal.com.hk/allweb/pdf/Principal_ORSOPD_Brochure_E.pdf";
export const brochureLinkOrsoPD_ZH = "https://iframe.principal.com.hk/allweb/pdf/Principal_ORSOPD_Brochure_C.pdf";
export const brochureLinkOrsoZH = "https://www.principal.com.hk/sites/default/files/general-files/ORSO_C.pdf";
export const bookletLinkConst = "https://www.principal.com.hk/sites/default/files/general-files/S800Member_Booklet_E.pdf";
export const bookletLinkZhConst = "https://www.principal.com.hk/sites/default/files/general-files/S800Member_Booklet_ZH.pdf";
export const noticeLinkConst = "https://iframe.principal.com.hk/allweb/001_Products_content.htm#LatestInformation";
export const brochureLinkSIMP_EN = "https://www.principal.com.hk/sites/default/files/general-files/Simple_E.pdf";
export const brochureLinkSIMP_ZH = "https://www.principal.com.hk/sites/default/files/general-files/Simple_C.pdf";
export const brochureLinkSMAP_EN = "https://www.principal.com.hk/sites/default/files/general-files/Smart_E.pdf";
export const brochureLinkSMAP_ZH = "https://www.principal.com.hk/sites/default/files/general-files/Smart_C.pdf";
export const termsOfUseLink = "https://www.principal.com.hk/terms-of-use";
export const termsOfUseZhLink = "https://www.principal.com.hk/zh/terms-of-use";
export const privStatLink = "https://www.principal.com.hk/privacy-statement";
export const privStatZhLink = "https://www.principal.com.hk/zh/privacy-statement";
export const contactUsLink = "https://www.principal.com.hk/contact-us";
export const contactUsZhLink = "https://www.principal.com.hk/zh/contact-us";
export const resourcesLink = "https://www.principal.com.hk/resources/faq";
export const resourcesZhLink = "https://www.principal.com.hk/zh/resources/faq";
export const picsLink = "https://www.principal.com.hk/rsc-pics";
export const picsZhLink = "https://www.principal.com.hk/zh/rsc-pics";
export const orosLink = "https://e-orso.principal.com.hk/orso/welcome.do?methodName=welcome";
export const custSvcNoS800 = "2827-1233";
export const linkForKeyNoteGfcS800_EN = "https://www.principal.com.hk/sites/default/files/general-files/S800_KeyNote_Member_E.pdf";
export const linkForKeyNoteGfcS800_ZH = "https://www.principal.com.hk/sites/default/files/general-files/S800_KeyNote_Member_C.pdf";
export const linkForKeyNoteGfcSmap_EN = "https://www.principal.com.hk/sites/default/files/general-files/Smart_KeyNote_Member_E.pdf";
export const linkForKeyNoteGfcSmap_ZH = "https://www.principal.com.hk/sites/default/files/general-files/Smart_KeyNote_Member_C.pdf";
export const faqGfcS800_EN = "https://www.principal.com.hk/sites/default/files/general-files/S800_FAQ_Member_E.pdf";
export const faqGfcS800_ZH = "https://www.principal.com.hk/sites/default/files/general-files/S800_FAQ_Member_C.pdf";
export const faqGfcSmap_EN = "https://www.principal.com.hk/sites/default/files/general-files/Smart_FAQ_Member_E.pdf";
export const faqGfcSmap_ZH = "https://www.principal.com.hk/sites/default/files/general-files/Smart_FAQ_Member_c.pdf";
//Currencies
export const usd = "USD";
export const hkd = "HKD";
export const aud = "AUD";
export const cny = "CNY";
export const jpy = "JPY";
export const HONG_KONG_DOLLAR = "HONG KONG DOLLAR";
export const US_DOLLAR = "US DOLLAR";
export const hk$ = "HK$";
export const us$ = "US$";
export const au$ = "AU$";
export const cn$ = "CN$";
export const jp$ = "JP$";

//Custom Attribute Text
export const S800 = "E-STATEMENT FOR S800 (O/Y/N)";
export const SIMP = "E-STATEMENT FOR SIMP (O/Y/N)";
export const SMAP = "E-STATEMENT FOR SMAP (O/Y/N)";

//Days
export const monToFri = "Monday to Friday";
export const saturday = "Saturday";
export const sunday = "Sunday";

//Timings
export const nineToSeven = "9 AM - 7 PM";
export const nineToOne = "9 AM - 1 PM";

//AIIB Notes
export const custSvc = "Customer Service Hotline";
export const custSvcNo = "4008 428 062";
export const closed = "CLOSED";
export const hkPublicHoliday = "Hong Kong Public Holidays";

//select Account AIIB
export const currency_US$ = "US$";
export const HKAIIB = "HKAIIB";
export const S800Acronym = "S800";

//AIIB Footer
export const copyright = "Copyright ©";
export const footerText = ". Principal Trust Company (Asia) Limited. All Rights Reserved.";

//AIIB fund constants
export const LSSAIIB = "(LSS)-AIIB";
export const LSSPOSTFIX = "-(LSS)";
export const DISPOSTFIX = "-(DIS)";
export const LSSInvNm = "(LSS)";
export const DISInvNm = "(DIS)";
export const LSS = "LSS";

//moneyTypeConstants
export const MAN = "MAN";
export const VOL = "VOL";
export const MANVOL = "MANVOL";
export const EE = "EE";
export const ER = "ER";
export const EREE = "EREE";
export const SEP = "SEP";
export const FORFEITURES = "FORFEITURES";
export const ER_ORSO = "ER ORSO";
export const EE_ORSO = "EE ORSO";
export const EE_VOL = "EE VOL";
export const ER_VOL = "ER VOL";
export const ER_RO = "ER RO";
export const EE_RO = "EE RO";
export const ER_PS = "ER PS";
export const EE_PS = "EE PS";
export const ER_MAN = "ER MAN";
export const ER_MAN_TFR = "ER MAN TFR";
export const EE_MAN_TFR = "EE MAN TFR";
export const EE_MAN_TFR_G = "EE MAN TFR-G";
export const EE_MAN = "EE MAN";
export const MAN_TFR = "MAN TFR";
export const VOL_TFR = "VOL TFR";
export const PS = "PS";
export const EE_OPT_VOL = "EE OPT VOL";
export const SEP_MAN = "SEP MAN";
export const SEP_MAN_TFR = "SEP MAN TFR";
export const SEP_MAN_TFR_G = "SEP MAN TFR-G";
export const SEP_VOL = "SEP VOL";
export const SEP_VOL_TFR = "SEP VOL TFR";
export const ER_SPECIAL = "ER SPECIAL";
export const POST_ER_SPECIAL = "POST ER SPECIAL";
export const POST_ER_ORSO = "POST ER ORSO";
export const POST_EE_ORSO = "POST EE ORSO";
export const POST_ER_ORSO_126 = "POST ER ORSO - 126";
export const POST_ER_RO_PS = "POST ER RO PS";
export const POST_ER_RO_CONT = "POST ER RO CONT";
export const POST_EE_RO_PS = "POST EE RO PS";
export const POST_EE_RO_CONT = "POST EE RO CONT";
export const POST_ER_PS = "POST ER PS";
export const POST_EE_PS = "POST EE PS";
export const POST_EE_VOL = "POST EE VOL";
export const EE_RO_PS = "EE_RO_PS";
export const ER_RO_PS = "ER RO PS";
export const EE_RO_CONT = "EE RO CONT";
export const ER_RO_CONT = "ER RO CONT";
export const EE_RO_INT = "EE RO INT";
export const ER_RO_INT = "ER RO INT";
export const ER_ORSO_108 = "ER ORSO - 108";
export const EE_VOL_TFR = "EE VOL TFR";

//Sub Transaction Name Constant
export const PI = "PI";
export const CD = "CD";
export const FD = "FD";
export const PD = "PD";
export const AI = "AI";
export const AO = "AO";
export const PA2_OVERPAY = "PA2-OVERPAY";
export const PRTL_DSTRB_OVERPAY = "PRTL DSTRB-OVERPAY";
export const SC9_UNIT_CREDIT = "SC9-UNIT CREDIT";
export const PAYROLL_CONTRIBUTION = "PAYROLL CONTRIBUTION";
export const PAYROLL_CNTB = "PAYROLL CNTB";
export const AC1_FORF_ALLOC_CNTB = "AC1-FORF ALLOC CNTB";
export const SC4_NORMAL = "SC4-NORMAL";
export const SPORADIC_CNTRB_NORMAL = "SPORADIC CNTRB-NORMAL";
export const SPOR_CNTRB_NORMAL = "SPOR CNTRB-NORMAL";
export const SC8_REAPPLY_PAYROLL_CNTB = "SC8-REAPPLY PAYROLL CNTB";
export const UNIT_CREDIT = "UNIT CREDIT";

//date Constanst
export const D_MMM_YYYY = "D MMM YYYY";
export const DD_MMM_YYYY = "DD MMM YYYY";
export const D_MMM_YYYY_CH = "YYYY年M月D日";
export const DDMMYYYY = "DD/MM/YYYY";
export const timestamp = "YYYY-MM-DD HH:mm:ss A";
export const DD_MM_YYYY_HH_mm_ss = "DD/MM/YYYY, HH:mm:ss";

//language constant
export const EN = "en";

//LSS Learn More COnstant
export const LearnMoreNote = "LSS is not a fund, it is a strategy that uses two constituent funds, namely the Principal International Bond Fund (LSS) and Principal International Equity Fund (LSS) to automatically reduce the risk exposure as the member approaches retirement age. ";

//fund switch constants
export const submit = "submit";
export const next = "next";
export const LTGF = "LTGF";
export const A65F = "A65F";
export const HKDSF = "HKDSF";
export const CGF = "CGF";
export const GUA = "GUA";
export const CPF = "CPF";

//LSS TransactionHistory DropDown Constant
export const fundLss = 'PRINCIPAL LIFESTYLE STRATEGY (LSS)';
export const lssEquityFund = 'PRINCIPAL INTERNATIONAL EQUITY FUND (LSS)';
export const lssBondFund = 'PRINCIPAL INTERNATIONAL BOND FUND (LSS)';

//AIIB Acc Balance Constants
export const EQUITY = "EQUITY";
export const EFFECTIVE_FIXED_INCOME = "Effective Fixed Income";
export const EFFECTIVE_EQUITY = "Effective Equity";

//ORSO Constants
export const ORSO = "ORSO";

//Logger Constants
export const SELECT_ASSET_PAGE = "Select Asset Page";
export const SELECT_CONTRIBUTION_PAGE = "Select Contribution Page";
export const SWITCH_OUT_PAGE = "Switch Out Page";
export const SWITCHOUT_ALLOCATION_PAGE = "Switch Out % allocation Page";
export const SWITCH_IN_PAGE = "Switch In Page";
export const CONFIRMATION_PAGE = "Confirmation Page";
export const TRNS_ACCPT_EXT_PAGE = "Transaction Accepted Existing Page";
export const CONTRIBUTION_RECORD = "Contribution Record";
export const CONTRIBUTION_DETAILS = "Contribution Details";
export const FUND_INFO = "Fund Info";
export const FUND_INFO_DETAILS = "Fund Info Details";
export const PORTFOLIO_PAGE = "Portfolio Page";
export const SIMPLIFIED_PAGE = "Simplified Page";
export const FEES_AND_CHARGES = "Fees and Charges Page";
export const ACC_BALANCE_BY_FUND = "Account Balance by Fund Page";
export const TRNS_CONTR_HST = "Transaction Contribution History Page";
export const CAHNGES = "Changes Page";
export const CAHNGES_DETAILS = "Changes Details Page";
export const FUND_PROCESSING = "Fund Processing Page";
export const SELECT_FUTURE_CONTRIBUTIONH = "Select Future Contribution Page";
export const FUTURE_ALLOCATION_CONTRIBUTION = "Future % Allocation Page";
export const FUTURE_TRNS_ACCEPTED = "Future Transacxtion Accepted Page";
export const PERSONAL_INFO = "Personal Info Page";
export const VERIFICATION = "Verification Page";
export const DOCUMENTS_PAGE = "Documents Page";
export const REPORTS_PAGE = "Reports Page";
export const GENERATE_REPORT_PAGE = "Generate Report Page";
export const AUTHENTICATE_OTP = "Authenticate OTP Page";
export const CHANGE_PIN_PAGE = "Change Pin Page";

export const CORE_ORSO_ACCOUNT_S800 = [105, 120, 141, 142, 143, 140, 124, 122, 125];
export const CORE_ORSO_ACCOUNT_S800_PRODUCT = ['HKP2', 'HKP3', 'HKP4', 'HKABN', 'HKBNP', 'HKCRC', 'HKMSK', 'HKSI', 'HKPD'];

//SIMP/SMAP Constants
export const COBRAND = "COBRAND";
export const SMP = "SMP";
export const ALL = "ALL";
export const SI = "SI";
export const SM = "SM";
export const SIMP_PRODUCT = "SIMP";
export const SMAP_PRODUCT = "SMAP";
export const custSvcNoSmap = "2802-2812";

export const type1 = "Type-1";
export const type2 = "Type-2";
export const type3 = "Type-3";
export const linNote1 = "If you are first-time login to our ";
export const linNote2 = "New Principal Retirement Service Center.";
export const linNote3 = " You are required to “Create a New Password” by using your current PIN/Password.";
export const linNoteZh = "如果您是第一次登入我們新的“信安退休服務中心”。 您需要使用現有的密碼來創建新的登入密碼。";

export const pollingAgrmntName = "POOLING AGREEMENT NAME";
export const cnNonCorpERName = "ZN - NON CORP ER";
export const schemeNameEn = "SCHEME NAME-ENGLISH";
export const schemeNameZh = "SCHEME NAME-CHINESE";
export const chngDate = "chgDt";
export const ZH = "zh";
export const enter = "Enter";
export const numPadEnter = "NumpadEnter";
export const accTo = "按";
export const znSchemeTypO = "O";
export const contactUsAiib = " Contact us at 4008 428 062 / ";
export const contactmail = "mailto:hkinfo@principal.com";

//Organization Type Name
export const indAcc = "IND ACCT";
export const LTG_PORTFOLIO = "LTG";
export const LTG_PORTFOLIO_FULL_NAME = "LONG TERM GUARANTEED PORTFOLIO";

//Account Types
export const accountTypPa = "PA";
export const accountTypeEe = "EE";
export const accountTypSep = "SEP";
export const accountTypTvc = "TVC";
export const accountTypSvc = "SVC";


//File Extention Types
export const PDF_EXTENTION = '.pdf';

// Terminated Funds Info
export const TERMINATED_FUND_INFO = {
    "100": {
        "fundName" : "PRINCIPAL LONG TERM GURANTEED FUND",
        "otherFundName" : "信安長線保證基金",
        "fundCode" : "LTGF"
    },
    "167": {
        "fundName" : "PRINCIPAL LONG TERM GURANTEED FUND",
        "otherFundName" : "信安長線保證基金",
        "fundCode" : "LTGF"
    },
    "176": {
        "fundName" : "PRINCIPAL LONG TERM GURANTEED FUND",
        "otherFundName" : "信安長線保證基金",
        "fundCode" : "LTGF"
    },
    "210": {
        "fundName" : "PRINCIPAL LONG TERM GURANTEED FUND",
        "otherFundName" : "信安長線保證基金",
        "fundCode" : "LTGF"
    },
    "480": {
        "fundName" : "PRINCIPAL LONG TERM GURANTEED FUND",
        "otherFundName" : "信安長線保證基金",
        "fundCode" : "LTGF"
    },
    "109": {
        "fundName" : "PRINCIPAL CAPITAL GUARANTEED FUND",
        "otherFundName" : "信安保本基金",
        "fundCode" : "CGF"
    },
    "168": {
        "fundName" : "PRINCIPAL CAPITAL GUARANTEED FUND",
        "otherFundName" : "信安保本基金",
        "fundCode" : "CGF"
    },
    "183": {
        "fundName" : "PRINCIPAL CAPITAL GUARANTEED FUND",
        "otherFundName" : "信安保本基金",
        "fundCode" : "CGF"
    },
    "207": {
        "fundName" : "PRINCIPAL CAPITAL GUARANTEED FUND",
        "otherFundName" : "信安保本基金",
        "fundCode" : "CGF"
    },
    "486": {
        "fundName" : "PRINCIPAL CAPITAL GUARANTEED FUND",
        "otherFundName" : "信安保本基金",
        "fundCode" : "CGF"
    },
    "506": {
        "fundName" : "PRINCIPAL CAPITAL GUARANTEED FUND",
        "otherFundName" : "信安保本基金",
        "fundCode" : "CGF"
    },
    "223": {
        "fundName" : "LONG TERM GUARANTEED PORTFOLIO",
        "otherFundName" : "長線保證組合",
        "fundCode" : "LTG"
    },
    "220": {
        "fundName" : "CAPITAL GUARANTEED PORTFOLIO",
        "otherFundName" : "本金保證組合",
        "fundCode" : "CG"
    }
};